import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { Form, RangeSlider } from '../../../components';

import css from './PriceFilterCustomForm.module.css';

const DEBOUNCE_WAIT_TIME = 400;

const PriceFilterCustomFormComponent = props => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, min, max, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('PriceFilterCustomForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'PriceFilterCustomForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const MIN_LIMIT = min;
  const MAX_LIMIT = max;

  const formatPrice = value => {
    const number = Number(value);
    return isNaN(number) ? '' : number.toLocaleString('en-US');
  };

  const parsePrice = value => {
    if (!value) return '';
    let parsedValue = Number(value.replace(/,/g, ''));
    if (isNaN(parsedValue)) return '';

    if (parsedValue < MIN_LIMIT) {
      parsedValue = MIN_LIMIT;
    } else if (parsedValue > MAX_LIMIT) {
      parsedValue = MAX_LIMIT;
    }
    return parsedValue;
  };


  const handleChange = debounce(
    formState => {
      if (formState.dirty) {
        const { minPrice, maxPrice, ...restValues } = formState.values;

        onChange({
          minPrice: minPrice === '' ? rest.min : minPrice,
          maxPrice: maxPrice === '' ? rest.max : maxPrice,
          ...restValues,
        });
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSubmit = values => {
    const { minPrice, maxPrice, ...restValues } = values;
    return onSubmit({
      minPrice: minPrice === '' ? rest.min : minPrice,
      maxPrice: maxPrice === '' ? rest.max : maxPrice,
      ...restValues,
    });
  };

  const formCallbacks = liveEdit
    ? { onSubmit: () => null }
    : { onSubmit: handleSubmit, onCancel, onClear };

  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          showAsPopup,
          onClear,
          onCancel,
          isOpen,
          isInSideBar,
          contentRef,
          style,
          intl,
          values,
          min,
          max,
          step,
          children,
          marketplaceCurrency,
        } = formRenderProps;
        const { minPrice: minPriceRaw, maxPrice: maxPriceRaw } = values;
        const minPrice = typeof minPriceRaw !== 'string' ? minPriceRaw : min;
        const maxPrice = typeof maxPriceRaw !== 'string' ? maxPriceRaw : max;

        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'PriceFilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'PriceFilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'PriceFilterForm.submit' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            contentRef={contentRef}
            style={{ minWidth: '300px', ...style }}
          >
            <div className={css.contentWrapper}>
              {showAsPopup ? (
                <span className={css.label}>
                  <FormattedMessage id="PriceFilterForm.priceMonth" />
                </span>
              ) : null}
              <div className={css.inputsWrapper}>
                <span className={css.minPriceHolder}>
                  <Field
                    className={classNames(css.minPrice, { [css.priceInSidebar]: isInSideBar })}
                    id={`${id}.minPrice`}
                    name="minPrice"
                    component="input"
                    type="text"
                    placeholder={MIN_LIMIT}
                    min={MIN_LIMIT}
                    max={MAX_LIMIT}
                    step={step}
                    format={formatPrice}
                    parse={parsePrice}
                  />
                  <span className={css.currency}>
                    {marketplaceCurrency === 'USD' && '$'}
                  </span>
                </span>
                <span className={css.priceSeparator}>-</span>
                <span className={css.maxPriceHolder}>
                  <Field
                    className={classNames(css.maxPrice, { [css.priceInSidebar]: isInSideBar })}
                    id={`${id}.maxPrice`}
                    name="maxPrice"
                    component="input"
                    type="text"
                    placeholder={MAX_LIMIT}
                    min={MIN_LIMIT}
                    max={MAX_LIMIT}
                    step={step}
                    format={formatPrice}
                    parse={parsePrice}
                  />
                  <span className={css.currency}>
                    {marketplaceCurrency === 'USD' && '$'}
                  </span>
                </span>
              </div>
            </div>

            <div className={css.sliderWrapper}>
              <RangeSlider
                min={MIN_LIMIT}
                max={MAX_LIMIT}
                step={step}
                handles={[minPrice, maxPrice]}
                onChange={handles => {
                  form.change('minPrice', handles[0]);
                  form.change('maxPrice', handles[1]);
                }}
              />
            </div>

            {values.minPrice === values.maxPrice && (
              <p className={css.error}>
                <FormattedMessage id="PriceFilterForm.validationError" />
              </p>
            )}

            {liveEdit ? (
              <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
            ) : (
              <div className={css.buttonsWrapper}>
                <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.cancelButton} type="button" onClick={handleCancel}>
                  {cancel}
                </button>

                {values?.maxPrice > values?.minPrice && (
                  <button className={css.submitButton} type="submit">
                    {submit}
                  </button>
                )}
              </div>
            )}
            {children}
          </Form>
        );
      }}
    />
  );
};

PriceFilterCustomFormComponent.defaultProps = {
  liveEdit: false,
  showAsPopup: false,
  isOpen: false,
  contentRef: null,
  style: null,
  min: 0,
  step: 1,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

PriceFilterCustomFormComponent.propTypes = {
  id: string.isRequired,
  liveEdit: bool,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  isOpen: bool,
  contentRef: func,
  style: object,
  min: number.isRequired,
  max: number.isRequired,
  step: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const PriceFilterCustomForm = injectIntl(PriceFilterCustomFormComponent);

export default PriceFilterCustomForm;
