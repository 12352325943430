import React from 'react';
import { bool } from 'prop-types';

import PriceFilterCustomPlain from './PriceFilterCustomPlain';
import PriceFilterCustomPopup from './PriceFilterCustomPopup';

const PriceFilterCustom = props => {
  const { showAsPopup, marketplaceCurrency, ...rest } = props;
  return showAsPopup ? (
    <PriceFilterCustomPopup marketplaceCurrency={marketplaceCurrency} {...rest} />
  ) : (
    <PriceFilterCustomPlain marketplaceCurrency={marketplaceCurrency} {...rest} />
  );
};

PriceFilterCustom.defaultProps = {
  showAsPopup: false,
};

PriceFilterCustom.propTypes = {
  showAsPopup: bool,
};

export default PriceFilterCustom;
